import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Box,
  Button,
  ButtonGroup,
  Dialog,
  DialogActions,
  DialogTitle,
  Tooltip,
} from '@mui/material';
import MaterialReactTable from 'material-react-table';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ASSIGN_SECTIONS, COURSES, SUPERVISIONS } from 'constants/endpoints';
import { NONDISPLAY_FIELDS } from 'constants/fields';
import {
  deleteQuery,
  deleteSupervisionQuery,
  revokeQuery,
} from 'store/actions';
import {
  selectCurrentSectionEndpoint,
  selectData,
  selectFirstPosition,
  selectYear,
} from 'store/selectors';

const DataTable = () => {
  const dispatch = useDispatch();

  const data = useSelector(selectData);
  const curr_section_endpoint = useSelector(selectCurrentSectionEndpoint);
  const year = useSelector(selectYear);
  const position = useSelector(selectFirstPosition);

  const [openDel, setOpenDel] = React.useState(false);
  const [itemID, setID] = React.useState(0);
  const [role, setRole] = React.useState(0);

  const handleClickDel = (id) => {
    setOpenDel(true);

    if (curr_section_endpoint === SUPERVISIONS) {
      setID(id.student_id);
    } else {
      setID(id.id);
    }

    if (curr_section_endpoint === COURSES) {
      setRole(id.role);
    }
  };

  const handleCloseDel = () => {
    setOpenDel(false);
    setID(0);
  };

  const deleteEntry = () => {
    if (ASSIGN_SECTIONS.includes(curr_section_endpoint)) {
      const payload = {
        position_id: position.id,
        year: year,
        ...(curr_section_endpoint === COURSES && { role: role }),
      };
      dispatch(revokeQuery(curr_section_endpoint, itemID, payload));
    } else if (curr_section_endpoint === SUPERVISIONS) {
      dispatch(
        deleteSupervisionQuery(
          curr_section_endpoint,
          itemID,
          position.id,
          year,
        ),
      );
    } else {
      dispatch(deleteQuery(curr_section_endpoint, itemID, position.id, year));
    }
    handleCloseDel();
  };

  const createColumns = (array, data) => {
    if (data.length > 0) {
      let list = Object.keys(data[0]);

      list.forEach((field, id) => {
        if (!NONDISPLAY_FIELDS.includes(field)) {
          let name = field;

          if (name.includes('_')) {
            name = name.split('_')[0];
          }

          array.push({
            accessorKey: field,
            header: name.charAt(0).toUpperCase() + name.slice(1),
          });
        }
      });
    }
    return array;
  };

  const columns = createColumns([], data);

  return (
    <Box spacing={1} mt={0.25}>
      <MaterialReactTable
        columns={columns}
        data={data}
        initialState={{
          columnVisibility: { id: false },
        }}
        enableRowActions
        renderRowActions={({ row }) => {
          return (
            <React.Fragment>
              <Tooltip title="Delete entry">
                <Button
                  color="error"
                  onClick={() => handleClickDel(row.original)}>
                  <DeleteIcon />
                </Button>
              </Tooltip>

              <Dialog open={openDel} onClose={handleCloseDel}>
                <DialogTitle id="alert-dialog-title">
                  {'Are you sure you want to delete this entry?'}
                </DialogTitle>

                <DialogActions>
                  <ButtonGroup
                    disableElevation
                    orientation="horizontal"
                    fullWidth
                    m={0.5}>
                    <Tooltip title="Delete entry">
                      <Button
                        variant="outlined"
                        fullWidth
                        onClick={deleteEntry}>
                        <CheckCircleIcon />
                      </Button>
                    </Tooltip>

                    <Tooltip title="Cancel">
                      <Button
                        variant="outlined"
                        fullWidth
                        color="error"
                        onClick={handleCloseDel}>
                        <CancelIcon />
                      </Button>
                    </Tooltip>
                  </ButtonGroup>
                </DialogActions>
              </Dialog>
            </React.Fragment>
          );
        }}
      />
    </Box>
  );
};

export default DataTable;
